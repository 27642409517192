// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-byuauth-js": () => import("./../../../src/pages/byuauth.js" /* webpackChunkName: "component---src-pages-byuauth-js" */),
  "component---src-pages-done-js": () => import("./../../../src/pages/done.js" /* webpackChunkName: "component---src-pages-done-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-page-js": () => import("./../../../src/pages/page.js" /* webpackChunkName: "component---src-pages-page-js" */),
  "component---src-pages-test-123-js": () => import("./../../../src/pages/test123.js" /* webpackChunkName: "component---src-pages-test-123-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../../../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */),
  "component---src-pages-uvuauth-js": () => import("./../../../src/pages/uvuauth.js" /* webpackChunkName: "component---src-pages-uvuauth-js" */)
}

